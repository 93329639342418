/*** image-gallery ***/
:root {
  --swiper-pagination-color: #106657;
  --tw-prose-links: #106657;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.text-green-main {
  color: rgb(105, 225, 94);
}

.bg-green-main {
  background-color: rgba(105, 225, 94, 0.1);
}

.text-warning-80 {
  color: #ff9900;
}

.bg-warning-80-10 {
  background-color: rgba(255, 153, 0, 0.1);
}

.text-violet-main {
  color: #7e43ba;
}

.bg-violet-main-10 {
  background-color: rgba(126, 67, 186, 0.1);
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover {
  border: 2px solid #106657 !important;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.react-datepicker-wrapper {
  flex: 1;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
}

.date-picker-custom {
  width: 270px;
}

.input-bg-custom {
  background: rgba(255, 255, 255, 0.5);
  border: 0.5px solid #ffffff;
  border-radius: 10px;
}

.custom-background::before {
  content: '';
  width: 100%;
  height: 140px;
  background-color: #106657;
  position: absolute;
  top: -1px;
  left: 0;
  z-index: -1;
}

@media (max-width: 425px) {
  .custom-background::before {
    height: 235px;
  }
}

.custom-btn-primary {
  background: linear-gradient(82.92deg, #106657 10.04%, #29cbae 78.12%);
  border: unset;
}

.custom-height-btn {
  height: 35px;
}

.custom-rounded-btn {
  border-radius: 18px;
}

.custom-chat-admin {
  background-color: #ffffff !important;
  border-radius: 0px 10px 10px 10px !important;
  margin-left: 15px;
}

.custom-chat-admin.ca-rec::before {
  top: 0;
  left: -12px;
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  border-top: 15px solid #ffffff;
  border-left: 12px solid transparent;
  border-right: 0;
}

.custom-chat-admin.border-white.ca-rec::before {
  border-top: 15px solid #ffffff;
}

.custom-chat-user {
  background-color: #106657 !important;
  border-radius: 10px 0px 10px 10px !important;
  color: white;
}

.custom-chat-user.chat-popup {
  border-radius: 10px 10px 0px 10px !important;
}

.chat-user {
  margin-right: 10px;
  background-color: #106657;
  color: white;
}

.custom-border-v2 {
  border: 1px solid rgba(82, 82, 82, 0.25);
}

.chat-user.cu-rec::before {
  top: 0;
  right: -6px;
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  border-top: 10px solid #106657;
  border-left: 0;
  border-right: 7px solid transparent;
  z-index: 0;
}

.custom-footer-chat {
  background-color: rgba(196, 196, 196, 0.1);
}

.line-custom {
  height: 5px;
  background-color: #dadada;
}

.ranger {
  height: 5px;
  background-color: #106657;
}

.ranger::before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 6px;
  position: absolute;
  left: -6px;
  background-color: #106657;
  top: 50%;
  transform: translateY(-50%);
}

.center-top {
  transform: translateY(-50%);
}

.ranger::after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 6px;
  position: absolute;
  right: -6px;
  background-color: #106657;
  top: 50%;
  transform: translateY(-50%);
}

.time-line .time-line-start {
  left: 0;
  top: calc(100% + 7px);
  transform: translateX(-50%);
  color: #737373;
}

.time-line .time-line-end {
  right: 0;
  top: calc(100% + 7px);
  transform: translateX(50%);
  color: #737373;
}

.top-full-8 {
  top: calc(100% + 8px);
}

.custom-line::after {
  content: '';
  height: 1px;
  width: 90px;
  position: absolute;
  color: #106657;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
}

.transfer.custom-line::after {
  background-color: rgb(255 113 13);
}

.atd.custom-line::after {
  background-color: #106657;
}

.text-2line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-1line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-6line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.underline-custom {
  position: relative;
}

.underline-custom::after {
  content: '';
  width: 100%;
  height: 3px;
  position: absolute;
  top: calc(100% - 3px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #106657;
}

.dot {
  width: 2px;
  height: 2px;
  background-color: #1b1b1b;
}

.text-user-chat {
  color: #3d465f;
}

.custom-placeholder::placeholder {
  color: #cfc4c2;
}

.custom-placeholder::-moz-placeholder {
  color: #cfc4c2;
}

.custom-placeholder::-webkit-input-placeholder {
  color: #cfc4c2;
}

.h-05 {
  height: 0.5px;
}

.rounded-10 {
  border-radius: 10px;
}

.chat-popup-item {
  width: 50px;
  height: 50px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
}

.show {
  display: none;
}

.hover-show:hover .show {
  display: flex;
}

.a-flip-left {
  animation: flipLeft 2s ease-out 1;
  opacity: 1 !important;
  visibility: visible !important;
}

@keyframes flipLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  70% {
    opacity: 1;
    transform: translateX(0);
  }
}

.gallery-background {
  position: fixed;
  z-index: 1000;
  padding-top: 10px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gallery-close {
  cursor: pointer;
  position: absolute;
  z-index: 1001;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  right: 0;
  top: 0;
  margin: 20px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  -webkit-animation-name: zoom;
  animation-name: zoom;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.gallery-image.img {
  all: unset;
  width: 100%;
  max-width: 1250px;
  height: 100%;
  max-height: 90vh;
  -webkit-animation-name: zoom;
  animation-name: zoom;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.gallery-prev,
.gallery-next {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: white;
  top: 50%;
  border-radius: 100%;
  transform: translateY(-50%);
  -webkit-animation-name: zoom;
  animation-name: zoom;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  z-index: 1001;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.gallery-next {
  right: 24px;
}

.gallery-prev {
  left: 24px;
}

.bg-gradient {
  background: linear-gradient(45.65deg, #106657 11.13%, #26bfa3 99.14%);
}

.custom-polygon-bottom::before {
  content: '';
  position: absolute;
  border-bottom: 8px solid white;
  border-left: 8px solid transparent;
  left: -7px;
  bottom: 0;
}

.custom-polygon-sticker::after {
  content: '';
  position: absolute;
  border-top: 10px solid white;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  right: 8px;
  top: 99.9%;
}

.custom-polygon-top::before {
  content: '';
  position: absolute;
  border-top: 8px solid white;
  border-left: 8px solid transparent;
  left: -7px;
  top: 0;
}

.menu-active {
  position: relative;
}

.menu-active::after,
.menu-item::after {
  content: '';
  width: 100%;
  height: 3px;
  background-color: #106657;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.menu-item::after {
  width: 0;
  transition: all 0.3s;
}

.menu-item:hover::after {
  width: 100%;
}

.slider-index-dots {
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 500px) {
  .slider-index-dots {
    bottom: 10px;
  }
}

.image-gallery-icon {
  display: none;
}

.slider-item,
.product-item {
  opacity: 0;
  animation: hidden 1s linear;
}

.slider-item.active,
.product-item.active {
  animation: active 1s linear;
  opacity: 1;
}

@keyframes active {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hidden {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

._title::before {
  content: '';
  width: calc(50% - 140px);
  height: 1px;
  background-color: rgba(155, 205, 196, 0.5);
  position: absolute;
  top: 50%;
  left: 0;
}

._title::after {
  content: '';
  width: calc(50% - 140px);
  height: 1px;
  background-color: rgba(155, 205, 196, 0.5);
  position: absolute;
  top: 50%;
  right: 0;
}

._title-product-detail::after,
._title-product-detail::before {
  width: calc(50% - 180px);
}

@media (max-width: 500px) {

  ._title-product-detail::after,
  ._title-product-detail::before {
    width: calc(50% - 120px);
  }
}

._title-product-new::after,
._title-product-new::before {
  width: calc(50% - 140px);
}

@media (max-width: 500px) {

  ._title-product-new::after,
  ._title-product-new::before {
    width: calc(50% - 120px);
  }
}

._title-news::after,
._title-news::before {
  width: calc(50% - 95px);
}

._title-live-detail::before,
._title-live-detail::after {
  width: calc(50% - 220px);
}

.p-50p {
  padding-bottom: 50%;
}

.p-100p {
  padding-bottom: 100%;
}

.p-66p {
  padding-bottom: calc(100% * 2 / 3);
}

.container {
  width: calc(100% - 400px);
  max-width: 1240px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 1224px) {
  .container {
    width: calc(100% - 96px);
  }
}

@media (max-width: 1023px) {
  .container {
    width: calc(100% - 48px);
  }
}

@media (max-width: 767px) {
  .container {
    width: calc(100% - 16px);
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  animation: fadeInDown 1.3s ease backwards;
}

.sticky.is-sticky {
  /* opacity: 1;
  animation: active 0.2s linear;
  visibility: visible;
  z-index: 999; */
  display: block;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.is-sticky {
  /* opacity: 0;
  animation: hidden 0.2s linear;
  visibility: hidden;
  z-index: -1; */
  display: none;
}

.text-3line {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
}

.text-2line {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
}

.text-1line {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
}

.bottom-100-12 {
  bottom: calc(100% + 12px);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--in-range {
  background-color: #106657;
}

/* animation */
.bubbling-heart div {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, 25vh) scale(0);
  opacity: 0;
  animation: bubbleUp 2s infinite;
}

.bubbling-heart div img {
  transform: translateX(12.5vw) rotate(25deg);
  animation: oscillate 4s ease-in-out infinite;
}

.bubbling-heart div:nth-child(1) {
  animation-delay: 0.5s;
}

.bubbling-heart div:nth-child(2) {
  animation-delay: 0.1s;
}

.bubbling-heart div:nth-child(3) {
  animation-delay: 1.5s;
}

.bubbling-heart div:nth-child(4) {
  animation-delay: 2s;
}

.bubbling-heart div:nth-child(1) img {
  animation-delay: 1s;
}

.bubbling-heart div:nth-child(2) img {
  animation-delay: 2s;
}

.bubbling-heart div:nth-child(3) img {
  animation-delay: 3s;
}

.bubbling-heart div:nth-child(4) img {
  animation-delay: 3s;
}

@keyframes bubbleUp {
  50% {
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -25vh) scale(1.2);
    opacity: 0;
  }
}

@keyframes oscillate {
  50% {
    transform: translateX(-12.5vw) rotate(-25deg);
  }
}

.product-images {
  width: 100%;
  /* height: 100%; */
}

.swiper-slide-thumb-active {
  background: #fbfcfc;
  border: 1px solid #106657;
  border-radius: 5px;
  overflow: hidden;
}

.custom-bg-info {
  box-shadow: 1px 0px 8px 5px rgba(0, 213, 174, 0.1);
  border-radius: 30px 30px 0px 0px;
}

@media (max-width: 425px) {

  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 30px !important;
  }
}

.swiper-slide-thumb-active {
  border-color: #106657 !important;
}

.referral-landing-title::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background-color: rgba(155, 205, 196, 0.5);
  left: 0;
  top: 50%;
  z-index: 1;
}

.__title-step::before,
.__title-step::after {
  width: calc(50% - 200px);
}

@media (max-width: 425px) {

  .__title-step::before,
  .__title-step::after {
    width: calc(50% - 120px);
  }
}

.border-color-custom-header {
  border-color: rgba(16, 102, 87, 0.25);
}

/* voucher */
.voucher-item {
  height: 7.375rem;
}

.image-voucher {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, transparent 0, transparent 0.1875rem, #59d4bf 0);
  border-top: 1px solid #59d4bf;
  border-bottom: 1px solid #59d4bf;
  border-radius: 0.125rem 0 0 0.125rem;
}

.saw-image::before,
.saw-image::after {
  content: '';
  position: absolute;
  width: 100%;
  background: #59d4bf;
  border-left: 1px solid #59d4bf;
}

.saw-image::before {
  bottom: -0.125rem;
  left: 0;
  height: 0.125rem;
  border-bottom: 1px solid #59d4bf;
  border-radius: 0 0 0 0.125rem;
}

.saw-image::before {
  top: -0.3125rem;
  height: 0.3125rem;
  border-radius: 0.125rem 0 0 0;
  border-top: 1px solid #59d4bf;
}

.saw-image {
  position: absolute;
  top: 0.3125rem;
  left: 0;
  width: 0.25rem;
  /* height: 0.5rem; */
  height: calc(100% - 0.4375rem);
  background: radial-gradient(circle at 0 0.25rem, transparent 0, transparent 0.1875rem, #59d4bf 0, #59d4bf 0.25rem);
  background-size: 0.25rem 0.625rem;
  background-repeat: repeat-y;
}

.react-transform-wrapper {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}

.product-loop-swiper .custom-next,
.product-loop-swiper .custom-prev {
  display: none !important;
}

.product-loop-swiper:hover .custom-prev,
.product-loop-swiper:hover .custom-next {
  display: block !important;
}

.swiper-horizontal>.swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
  height: 2px !important;
  bottom: 0;
  left: 0;
  width: 100% !important;
}

.swiper-scrollbar-drag {
  background-color: #c4c4c4 !important;
}

.menu-parent .menu-children {
  display: none;
}

.menu-parent:hover .menu-children {
  display: block;
}

th,
td,
table th,
table td {
  border: 1px solid #eaeaea;
  padding: 6px 10px;
}

.category-scrollbar::-webkit-scrollbar {
  height: 0;
}

.skeleton {
  background: linear-gradient(90deg, #eeeeee 25%, #dedede 50%, #eeeeee 75%);
  background-size: 200% 100%;
  animation: loading 2s infinite ease-in-out;
}

@keyframes loading {

  /* 0% {
    background-position: 200% 0;
  } */
  100% {
    background-position: -200% 0;
  }
}

.content-article img {
  display: inline-block;
  margin: 0;
}

a {
  color: #106657;
}

/* Animations */

@keyframes expandWidth {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.animate-expanding {
  animation: expandWidth 0.8s forwards;
}

@keyframes shrinkWidth {
  from {
    width: 100%;
  }

  to {
    width: 0;
  }
}

.animate-shrinking {
  animation: shrinkWidth 0.8s forwards;
}

.transition-height {
  -webkit-transition: height 0.5s ease;
  -moz-transition: height 0.5s ease;
  -ms-transition: height 0.5s ease;
  -o-transition: height 0.5s ease;
  transition: height 0.5s ease;
  overflow: hidden;
}

.app-toggle.react-toggle--checked .react-toggle-track {
  background-color: #106657;
}

.app-toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #106657;
}

.app-toggle.react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
}

.react-player.home-slider-video video {
  object-fit: cover;
}